import { Navigate, type Params, useParams } from "react-router-dom";

export interface RouteContext {
  params: Readonly<Params<string>>;
}

interface RedirectRouterProps {
  context2path: (context: RouteContext) => string;
}

export default function RedirectRouter(props: RedirectRouterProps) {
  const params = useParams();
  const context = { params };
  return <Navigate to={props.context2path(context)} replace />;
}
