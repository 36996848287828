import React from "react";
import { Box } from "@mui/material";

export interface PageProps {
  title: string;
  description?: string;
  children?: React.ReactNode;
  meta?: Array<
    React.DetailedHTMLProps<
      React.MetaHTMLAttributes<HTMLMetaElement>,
      HTMLMetaElement
    >
  >;
}

function Page(props: PageProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const { title, description, children, meta = [], ...others } = props;

  // Dynamically set the title in the HTML head
  React.useEffect(() => {
    document.title = `${title} | MCXess`;
  }, [title]);

  return (
    <>
      <meta name="description" content={description} />
      {meta?.map((item, index) => <meta key={index} {...item} />)}
      <Box
        ref={ref}
        {...others}
        sx={{
          height: "inherit",
          width: "100%",
          minHeight: "inherit",
          minWidth: "100%",
          bgcolor: "background.neutral",
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default React.forwardRef<HTMLDivElement, PageProps>(Page);
