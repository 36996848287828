import { type ApiResponse } from "../../../types";
import CSTPlatform from "../../../CSTPlatform";
import {
  type CreateItemRequest,
  type ListItemsRequest,
  type ListItemsResponse,
  type UpdateItemRequest,
} from "./item.dto";
import { type Item } from "./item.types";

const ItemApi = {
  async create<A>(
    collection: string,
    request: CreateItemRequest
  ): Promise<ApiResponse<Item<A>>> {
    const response = await CSTPlatform.getInstance().post(
      `/organizations/me/${collection}`,
      request
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async list<A>(
    collection: string,
    request: ListItemsRequest
  ): Promise<ApiResponse<ListItemsResponse<A>>> {
    const response = await CSTPlatform.getInstance().get(
      `/organizations/me/${collection}`,
      { params: request }
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async get<A>(collection: string, id: string): Promise<ApiResponse<Item<A>>> {
    const response = await CSTPlatform.getInstance().get(
      `/organizations/me/${collection}/${id}`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async update<A>(
    collection: string,
    id: string,
    request: UpdateItemRequest
  ): Promise<ApiResponse<Item<A>>> {
    const response = await CSTPlatform.getInstance().patch(
      `/organizations/me/${collection}/${id}`,
      request
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async delete<A>(
    collection: string,
    id: string
  ): Promise<ApiResponse<Item<A>>> {
    const response = await CSTPlatform.getInstance().delete(
      `/organizations/me/${collection}/${id}`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
};

export default ItemApi;
