import { Auth0Client } from "@auth0/auth0-spa-js";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import Config from "../../app/config";
import Root from "../../routes/paths";
import logger from "../../utils/logging";
import CSTPlatform from "../../services/cst-platform";

export const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isLoading: true,
  error: null,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // Loading
    startLoading(state) {
      state.isLoading = true;
    },
    initSuccess(state, action) {
      const { isAuthenticated, user } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.isInitialized = true;
      state.user = user;
      state.isLoading = false;
    },
    logoutSuccess(state) {
      state.isInitialized = true;
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

// Reducer
export default slice.reducer;

export const { startLoading, reset } = slice.actions;

// ===============================================

const auth0Client = new Auth0Client({
  clientId: Config.Provider.Auth0.clientId,
  domain: Config.Provider.Auth0.domain,
});

const authorizationParams = {
  audience: Config.Provider.Auth0.audience,
  redirect_uri: window.location.origin + Root.Auth.Redirect.pathname,
  scope: "openid name picture email",
};

async function attachTokenToAxios() {
  const token = await auth0Client.getTokenSilently({ authorizationParams });
  // log in development environment
  if (process.env.NODE_ENV === "development") {
    logger.info("AUTH :: attachTokenToAxios :: token", token);
  }
  CSTPlatform.attachToken(token);
  logger.info("AUTH :: attachTokenToAxios :: token attached to axios");
}

// ===============================================

/**
 * Checks if the user is authenticated and initializes the state and attaches the token to axios
 *
 * @returns {(function(): Promise<void>)|*}
 */
export function initialize() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await auth0Client.checkSession({ authorizationParams });
      const isAuthenticated = await auth0Client.isAuthenticated();
      logger.info("AUTH :: initialize :: isAuthenticated", isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        await attachTokenToAxios();
        dispatch(slice.actions.initSuccess({ isAuthenticated, user }));
        logger.info("AUTH :: initialize :: user");
      } else {
        // TODO: make sure to clear token from backend
        dispatch(slice.actions.logoutSuccess());
      }
    } catch (error) {
      logger.debug("AUTH :: initialize :: error", error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Redirects the user to Auth0 to login
 * @returns {(function(): Promise<void>)|*}
 */
export function login() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      logger.info("AUTH :: login");
      await auth0Client.loginWithRedirect({ authorizationParams }); // user leaves the app
    } catch (error) {
      logger.debug("AUTH :: initialize :: error", error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function logout() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: must clear token from backend
      CSTPlatform.clearToken();
      // await UserService.clearSession();
      logger.info("AUTH :: logout");
      await auth0Client.logout({
        logoutParams: {
          returnTo: window.location.origin + Root.Auth.Login.pathname,
        },
      });
      dispatch(slice.actions.logoutSuccess());
    } catch (error) {
      logger.debug("AUTH :: initialize :: error", error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * Precondition: user has been redirected back to the app from Auth0
 * @returns {(function(): Promise<void>)|*}
 */
export function handleRedirect() {
  return async () => {
    dispatch(slice.actions.startLoading());
    logger.info("AUTH :: handling redirect");
    try {
      await auth0Client.handleRedirectCallback();
      await attachTokenToAxios(); // server needs to be aware of the new token
      logger.info("AUTH :: handleRedirect :: user was logged in");
      void dispatch(initialize());
    } catch (error) {
      logger.debug("AUTH :: redirect :: error", error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ===============================================
