import * as Yup from "yup";

const Config = {
  measurementId: process.env.REACT_APP_PROVIDER_GA_MEASUREMENT_ID,
};

export const AnalyticsSchema = Yup.object().shape({
  measurementId: Yup.string().required(),
});

export type AnalyticsConfig = Yup.InferType<typeof AnalyticsSchema>;

export default Config as AnalyticsConfig;
