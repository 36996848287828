import axios, { type AxiosInstance } from "axios";
import { type Account, type ApiResponse } from "./types";
import Config from "../../app/config";
import OrganizationApi from "./api/Organization/organization.api";
import CubeApi from "./api/Cube/cube.api";

/**
 * cst-platform API
 * tree format: <Resource 1>.<Resource 2>. ... .<Resource N>.<Method> -> Promise<ApiResponse<Resource N>>
 */
export default class CSTPlatform {
  static $axios: AxiosInstance;

  static Account = {
    async get(): Promise<ApiResponse<Account>> {
      const response = await CSTPlatform.getInstance().get("/accounts/me");
      return {
        payload: response.data,
        statusCode: response.status,
        message: response.statusText,
      };
    },
  };

  static Organization = OrganizationApi;
  static Cube = CubeApi;

  static getInstance() {
    if (!this.$axios) {
      this.$axios = axios.create({
        baseURL: Config.Provider.Base.uri,
        withCredentials: true,
      });
    }
    return this.$axios;
  }

  static attachToken(token: string) {
    this.getInstance().defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  static clearToken() {
    delete this.getInstance().defaults.headers.common.Authorization;
  }
}
