import Root from "../paths";
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import AuthGuard from "../../gaurds/AuthGuard";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";
import { lazy } from "react";
import ContextBasedRouter, {
  type RouteContext,
} from "../../components/RedirectRouter";

const WorkspaceCreate = Loadable(
  lazy(async () => await import("../../pages/workspace/WorkspaceCreate"))
);
const WorkspaceCreateCustomWizard = Loadable(
  lazy(
    async () =>
      await import("../../pages/workspace/WorkspaceCreateCustomWizard")
  )
);

const WORKSPACE_ROUTES = [
  {
    path: Root.Workspace.Create.pathname,
    element: (
      <AuthGuard>
        <WorkspaceCreateCustomWizard />
      </AuthGuard>
    ),
  },
  {
    path: Root.Workspace.Create.Custom.pathname,
    element: (
      <AuthGuard>
        <WorkspaceCreateCustomWizard />
      </AuthGuard>
    ),
  },
  {
    path: Root.Workspace.name,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <Navigate to={Root.Dashboard.Workspace.List.pathname} replace />
        ),
        index: true,
      },
    ],
  },
  {
    path: Root.Workspace.Get(":projectId").pathname,
    element: (
      <AuthGuard>
        <ContextBasedRouter
          context2path={(context: RouteContext) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Root.Studio.Call.Get(context.params.projectId!).Analytics.Browser
              .pathname
          }
        />
      </AuthGuard>
    ),
    index: true,
  },
];

export default WORKSPACE_ROUTES;
