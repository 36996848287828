import { useState, useEffect, useRef } from "react";

export const usePromise = <T = any, E = Error>(
  factory: () => Promise<T>,
  defaultValue?: T
): [T | undefined, boolean, E | null] => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<T | undefined>(defaultValue);
  const [error, setError] = useState<E | null>(null);

  // Use a ref to track if the data has been fetched
  const isDataFetched = useRef(false);

  useEffect(() => {
    if (!isDataFetched.current) {
      const fetchData = async () => {
        try {
          const result = await factory();
          setData(result);
        } catch (e) {
          setError(e as E);
        } finally {
          setLoading(false);
        }
      };
      void fetchData();
      isDataFetched.current = true;
    }
  }, [factory]);

  return [data, loading, error];
};
