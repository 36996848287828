import { useMemo } from "react";
// @mui
import { CssBaseline, type ThemeOptions } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles";
// hooks
import useSettings from "../../hooks/useSettings";
//
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";

// ----------------------------------------------------------------------
interface PaletteCustomColorOptions {
  violet: string;
}

declare module "@mui/material/styles" {
  interface TypeBackground {
    contrast?: string;
    neutral?: string;
  }

  interface Palette {
    neutral: Palette["primary"];
    custom: PaletteCustomColorOptions;
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

// ----------------------------------------------------------------------

interface ThemeProviderProps {
  children: React.ReactNode;
}

export default function ThemeProvider({ children }: ThemeProviderProps) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === "light";

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions as any as ThemeOptions);
  theme.components = componentsOverride(theme);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
