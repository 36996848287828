import React from "react";
import { motion as m } from "framer-motion";
import { Button, Typography, Box } from "@mui/material";
import Page from "../components/Page";
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <Page title="Not Found">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        width="100vw"
        textAlign="center"
      >
        <m.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Typography variant="h1" component="h1" fontSize="4rem">
            404
          </Typography>
        </m.div>
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Typography variant="h4" fontSize="2rem">
            Oops... we couldn&apos;t find this page
          </Typography>
        </m.div>
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <Link to="/">
            <Button
              color="primary"
              variant="contained"
              size="large"
              sx={{ mt: 5 }}
            >
              Return
            </Button>
          </Link>
        </m.div>
      </Box>
    </Page>
  );
}
