import { Navigate, useSearchParams } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
import Root from "../routes/paths";
// routes

// ----------------------------------------------------------------------

interface GuestGuardProps {
  children: React.ReactNode;
}

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const [searchParams] = useSearchParams();
  //
  // if (!isInitialized) {
  //     return <LoadingScreen isActive={true}/>;
  // }

  if (isAuthenticated) {
    return (
      <Navigate
        to={searchParams.get("requested_page") ?? Root.Dashboard.pathname}
      />
    );
  }

  return <>{children}</>;
}
