import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CallStudio from "../../services/cst-platform";
import logging from "../../utils/logging";
import { type BaseWorkspace } from "../../services/cst-platform/api/Organization/Workspace/workspace.types";
import { type UpdateWorkspaceRequest } from "../../services/cst-platform/api/Organization/Workspace/workspace.dao";

export interface WorkspaceState {
  status: "loading" | "success" | "error";
  selected: BaseWorkspace | null;
  workspaces: BaseWorkspace[];
  error?: Error;
}

export const initialState: WorkspaceState = {
  status: "loading",
  selected: null,
  workspaces: [],
};

export const initialize = createAsyncThunk(
  "workspace/init",
  async (_, { dispatch }) => {
    dispatch(startLoading());
    try {
      const { payload } = await CallStudio.Organization.Workspace.list();
      dispatch(initSuccess(payload));
    } catch (error) {
      console.log("workspace :: initialize :: error", error);
      logging.debug(error);
      dispatch(enterError(error));
    }
  }
);

export const update = createAsyncThunk(
  "workspace/update",
  async (
    { id, details }: { id: string; details: UpdateWorkspaceRequest },
    { dispatch }
  ) => {
    dispatch(startLoading());
    try {
      const { payload } = await CallStudio.Organization.Workspace.update(
        id,
        details
      );
      await dispatch(initialize());
    } catch (error) {
      logging.debug(error);
      dispatch(enterError(error));
    }
  }
);

export const updateAttributes = createAsyncThunk(
  "workspace/updateAttributes",
  async ({ id, attributes }: { id: string; attributes: any }, { dispatch }) => {
    dispatch(startLoading());
    try {
      const { payload } =
        await CallStudio.Organization.Workspace.updateAttributes(
          id,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          attributes
        );
      await dispatch(initialize());
    } catch (error) {
      logging.debug(error);
      dispatch(enterError(error));
    }
  }
);

const slice = createSlice({
  name: "project",
  initialState,
  reducers: {
    startLoading(state) {
      state.status = "loading";
    },
    enterError(state, action) {
      state.status = "error";
      state.error = action.payload;
    },
    initSuccess(state, action) {
      state.status = "success";
      state.workspaces = action.payload;
      // if there was a selected workspace, update it
      if (state.selected) {
        state.selected =
          state.workspaces.find(p => p.id === state.selected?.id) ?? null;
      }
    },
    reset(state) {
      Object.assign(state, initialState);
    },
    selectWorkspaceById(state, action) {
      state.selected =
        state.workspaces.find(p => p.id === action.payload) ?? null;
    },
  },
});

// Reducer
export default slice.reducer;

export const {
  startLoading,
  enterError,
  reset,
  initSuccess,
  selectWorkspaceById,
} = slice.actions;

// ===============================================
