import { type ApiResponse } from "../../types";
import CSTPlatform from "../../CSTPlatform";
import { type Organization } from "./organization.types";
import WorkspaceApi from "./Workspace/workspace.api";
import UploadApi from "./Upload/upload.api";
import CallApi from "./Call/call.api";
import ItemApi from "./Item/item.api";

const OrganizationApi = {
  async update(organization: {
    name: string;
  }): Promise<ApiResponse<Organization>> {
    const response = await CSTPlatform.getInstance().put(
      "/accounts/me/organization",
      organization
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  Workspace: WorkspaceApi,
  Upload: UploadApi,
  Call: CallApi,
  Item: ItemApi,
};

export default OrganizationApi;
