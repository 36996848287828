import React from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Paper,
  Popover,
  Stack,
} from "@mui/material";
import { Outlet, useLocation, Link as RouterLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Root from "../../routes/paths";
import ReactIcon from "../../components/ReactIcon";
import useSettings from "../../hooks/useSettings";
import Logo from "../../components/Logo";
import useWorkspace from "../../hooks/useWorkspace";

interface NavigationConfig {
  pathname: string;
  label: string;
  icon?: string;
  children?: NavigationConfig[];
}

const DASHBOARD_NAVIGATION_CONFIG: NavigationConfig[] = [
  // { pathname: Root.Dashboard.Workspace.List.pathname, label: "Workflows" },
  // { pathname: Root.Dashboard.Settings.pathname, label: "Settings" },
];

function UserContextButton() {
  const auth = useAuth();
  const user: any = auth.user;
  const onLogout = auth.logout;
  const theme = useTheme();
  const settings = useSettings();
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onToggleDarkMode = () => {
    settings.onToggleMode();
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const toggleModeActionName =
    theme.palette.mode === "light" ? "Dark Mode" : "Light Mode";
  const toggleModeActionIcon =
    theme.palette.mode === "light" ? "MdDarkMode" : "MdLightMode";

  return (
    <React.Fragment>
      <Button
        color="inherit"
        variant="contained"
        fullWidth
        size="small"
        startIcon={
          <Avatar
            sx={{
              width: theme.spacing(3.5),
              height: theme.spacing(3.5),
              m: theme.spacing(0.5),
            }}
            alt={user.name}
            src={user.photoURL}
          />
        }
        onClick={handleClick}
        sx={{
          justifyContent: "flex-start",
          pl: theme.spacing(1),
          fontWeight: "bold",
          bgcolor: "background.default",
          "&:hover": {
            bgcolor: "background.paper",
          },
        }}
      >
        <Typography
          sx={{ opacity: 0.75 }}
          color="text.primary"
          fontFamily="inherit"
          fontStyle="inherit"
          fontWeight="inherit"
          variant="overline"
        >
          {user.displayName}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {anchorEl?.parentElement?.clientWidth && (
          <Box p={theme.spacing(1)} width={"fit-content"}>
            <Button
              size="small"
              variant="text"
              onClick={onToggleDarkMode}
              fullWidth
              sx={{ justifyContent: "flex-start", pl: theme.spacing(1) }}
              startIcon={<ReactIcon name={toggleModeActionIcon} />}
            >
              {toggleModeActionName}
            </Button>
            <RouterLink to={Root.Dashboard.Settings.pathname}>
              <Button
                size="small"
                variant="text"
                fullWidth
                sx={{ justifyContent: "flex-start", pl: theme.spacing(1) }}
                startIcon={<ReactIcon name="MdSettings" />}
              >
                Settings
              </Button>
            </RouterLink>
            <Button
              size="small"
              variant="text"
              color="error"
              onClick={onLogout}
              fullWidth
              sx={{ justifyContent: "flex-start", pl: theme.spacing(1) }}
            >
              Logout
            </Button>
          </Box>
        )}
      </Popover>
    </React.Fragment>
  );
}

function DashboardSidebar({
  config,
}: {
  config: Array<{ pathname: string; label: string; icon?: string }>;
}) {
  const location = useLocation();
  const theme = useTheme();
  const workspace = useWorkspace();
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={{
        borderRadius: 0,
        height: "100%",
        p: theme.spacing(2),
        borderRight: `3px solid ${theme.palette.divider}`,
      }}
    >
      {workspace.selected && (
        <Box mb={1} p={1}>
          <Box>
            <Stack direction="row" spacing={2}>
              <ReactIcon
                name={"MdGroupWork"}
                style={{
                  fontSize: theme.spacing(3.5),
                  marginBlock: "auto",
                  alignSelf: "center",
                }}
              />
              <Stack textAlign="left">
                <Typography
                  component="span"
                  variant="subtitle2"
                  padding={0}
                  gutterBottom={false}
                >
                  {workspace.selected.name}
                </Typography>
                <Typography
                  fontSize={theme.spacing(1.5)}
                  variant="subtitle2"
                  color="text.secondary"
                  padding={0}
                  fontWeight="light"
                  gutterBottom={false}
                >
                  Workspace
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      )}
      {config.map(link => (
        <Button
          key={link.pathname}
          component={RouterLink}
          to={link.pathname}
          variant={link.pathname === location.pathname ? "contained" : "text"}
          color={link.pathname === location.pathname ? "primary" : "inherit"}
          size="medium"
          fullWidth
          sx={{
            justifyContent: "flex-start",
            fontWeight: link.pathname === location.pathname ? "bold" : "normal",
          }}
          {...(link.icon && {
            startIcon: <ReactIcon name={link.icon} />,
          })}
        >
          {link.label}
        </Button>
      ))}
    </Paper>
  );
}

export default function DashboardLayout({
  navigationConfig = DASHBOARD_NAVIGATION_CONFIG,
}) {
  const theme = useTheme();
  const hasNavigationBar = navigationConfig.length > 0;

  const handleHelpClick = () => { };
  return (
    <Stack height="100%">
      <DashboardTopBanner />
      <Box height="6vh" />
      <Grid container bgcolor={theme.palette.background.neutral} height="94vh">
        {hasNavigationBar && (
          <Grid item xs={12} md={3} xl={2} sx={{ p: theme.spacing(0) }}>
            <Box height="100%">
              <DashboardSidebar config={navigationConfig} />
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          md={!hasNavigationBar ? 12 : 9}
          xl={!hasNavigationBar ? 12 : 10}
          overflow="auto"
          height="inherit"
        >
          <Outlet />
        </Grid>
      </Grid>
    </Stack>
  );
}

function DashboardTopBanner({ name = "CallStudio" }) {
  const theme = useTheme();
  const location = useLocation();
  const pathname = location.pathname;
  let suffix = "";
  const workspaceListName = Root.Dashboard.Workspace.List.pathname;
  if (pathname.match(Root.Studio.Call.name)) {
    suffix = "";
  } else if (pathname.match(workspaceListName)) {
    suffix = "Teams";
  }

  return (
    <Stack
      sx={{
        bgcolor: theme.palette.primary.main,
        px: theme.spacing(2),
        py: theme.spacing(1),
        borderBottom: `1.5px solid ${theme.palette.divider}`,
      }}
      direction="row"
      height="fit-content"
      width="100%"
      position="fixed"
      zIndex={theme.zIndex.appBar}
    >
      <Stack direction="row" aria-label="logo" pt={theme.spacing(0.25)}>
        <Logo sx={{ height: "inherit", my: "auto" }} />
      </Stack>
      <Stack direction="row" aria-label="logo">
        <Typography
          variant="h5"
          component="h1"
          ml={1}
          my="auto"
          color={theme.palette.common.white}
          fontFamily="Roboto"
        >
          <span style={{ color: theme.palette.secondary.main }}>Call</span>Studio
        </Typography>
      </Stack>
      <Box my="auto" sx={{ cursor: "default", display: "flex" }}>
        <Typography
          variant="subtitle2"
          component="h2"
          ml={1}
          my="auto"
          color="text.secondary"
          alignSelf="center"
        >
          {suffix}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box my="auto" alignSelf="center" pr={theme.spacing(2)}>
        <Box></Box>
        {/* {endComponent} */}
      </Box>
      <Box maxWidth={theme.spacing(52)} height="100%">
        <UserContextButton />
      </Box>
    </Stack>
  );
}
