import React, { createContext, useEffect } from "react";
import { dispatch, type RootState, useSelector } from "../redux/store";
import {
  initialize,
  initialState,
  type WorkspaceState,
  selectWorkspaceById,
  update,
} from "../redux/slices/workspace";
import { type UpdateWorkspaceRequest } from "../services/cst-platform/api/Organization/Workspace/workspace.dao";

// ----------------------------------------------------------------------

const WorkspaceContext: React.Context<WorkspaceState & any> = createContext({
  ...initialState,
  select: async (id: string) => {
    await Promise.resolve();
  },
  initialize: async () => {
    await Promise.resolve();
  },
  update: async (id: string, updates: UpdateWorkspaceRequest) => {
    await Promise.resolve();
  },
});

// ----------------------------------------------------------------------

interface WorkspaceProviderProps {
  children: React.ReactNode;
}

function WorkspaceProvider({ children }: WorkspaceProviderProps) {
  const state: WorkspaceState = useSelector(
    (state: RootState) => state.workspace
  );
  let needsInitialization = state.status === "loading";

  // Initialize the workspace.ts state
  useEffect(() => {
    if (needsInitialization) {
      void dispatch(initialize());
      needsInitialization = false;
    }
  }, []);

  const methods = {
    select: (id: string) => dispatch(selectWorkspaceById(id)) as any,
    initialize: () => dispatch(initialize()) as any,
    update: (id: string, details: UpdateWorkspaceRequest) =>
      dispatch(update({ id, details })) as any,
  };

  return (
    <WorkspaceContext.Provider
      value={{
        ...state,
        ...methods,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
}

export { WorkspaceContext, WorkspaceProvider };
