import { createContext, useEffect, useState, type ReactNode } from "react";

// utils
import Cookies from "js-cookie";
import getColorPresets, {
  colorPresets,
  defaultPreset,
} from "../utils/getColorPresets";
import Config from "../app/config";

// config
const { defaultSettings, cookiesKey, cookiesExpires } = Config.Module.Settings;

// ----------------------------------------------------------------------

type Settings = typeof defaultSettings;

interface SettingsContextProps extends Settings {
  onChangeMode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleMode: () => void;
  onChangeDirection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeColor: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLayout: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleStretch: () => void;
  onResetSetting: () => void;
  setColor: any; // Replace 'any' with the correct type for setColor
  colorOption: Array<{
    name: string;
    value: string;
  }>;
}

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onChangeMode: () => {},
  onToggleMode: () => {},
  onChangeDirection: () => {},
  onChangeColor: () => {},
  onChangeLayout: () => {},
  onToggleStretch: () => {},
  onResetSetting: () => {},
  setColor: defaultPreset,
  colorOption: [],
};

const SettingsContext = createContext<SettingsContextProps>(initialState);

// ----------------------------------------------------------------------

interface SettingsProviderProps {
  children: ReactNode;
  defaultSettings: Settings;
}

function SettingsProvider({
  children,
  defaultSettings,
}: SettingsProviderProps) {
  const [settings, setSettings] = useSettingCookies(defaultSettings);

  const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeMode: event.target.value,
    });
  };

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === "light" ? "dark" : "light",
    });
  };

  const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeDirection: event.target.value,
    });
  };

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColorPresets: event.target.value,
    });
  };

  const onChangeLayout = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeLayout: event.target.value,
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    });
  };

  const onResetSetting = () => {
    setSettings({
      themeMode: initialState.themeMode,
      themeLayout: initialState.themeLayout,
      themeStretch: initialState.themeStretch,
      themeDirection: initialState.themeDirection,
      themeColorPresets: initialState.themeColorPresets,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onChangeMode,
        onToggleMode,
        onChangeDirection,
        onChangeColor,
        setColor: getColorPresets(settings.themeColorPresets),
        colorOption: colorPresets.map(color => ({
          name: color.name,
          value: color.main,
        })),
        onToggleStretch,
        onChangeLayout,
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };

// ----------------------------------------------------------------------

function loadSettingsFromCookies() {
  const themeMode = Cookies.get(cookiesKey.themeMode);
  const themeDirection = Cookies.get(cookiesKey.themeDirection);
  const themeColorPresets = Cookies.get(cookiesKey.themeColorPresets);
  const themeLayout = Cookies.get(cookiesKey.themeLayout);
  const themeStretch = Cookies.get(String(cookiesKey.themeStretch));

  return {
    ...(themeMode != null && { themeMode }),
    ...(themeDirection != null && { themeDirection }),
    ...(themeColorPresets != null && { themeColorPresets }),
    ...(themeLayout != null && { themeLayout }),
    ...(themeStretch != null && { themeStretch: JSON.parse(themeStretch) }),
  };
}

function useSettingCookies(defaultSettings: Settings) {
  const [settings, setSettings] = useState<Settings>({
    ...defaultSettings,
    ...loadSettingsFromCookies(),
  });

  const onChangeSetting = () => {
    Cookies.set(cookiesKey.themeMode, settings.themeMode, {
      expires: cookiesExpires,
    });
    Cookies.set(cookiesKey.themeDirection, settings.themeDirection, {
      expires: cookiesExpires,
    });
    Cookies.set(cookiesKey.themeColorPresets, settings.themeColorPresets, {
      expires: cookiesExpires,
    });
    Cookies.set(cookiesKey.themeLayout, settings.themeLayout, {
      expires: cookiesExpires,
    });
    Cookies.set(
      String(cookiesKey.themeStretch),
      JSON.stringify(settings.themeStretch),
      {
        expires: cookiesExpires,
      }
    );
  };

  useEffect(() => {
    onChangeSetting();
  }, [settings]);

  return [settings, setSettings] as const;
}
