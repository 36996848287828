import "./App.css";
import "reactflow/dist/style.css";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "../redux/store";
import logger from "../utils/logging";
import Config from "./config";
import Router from "../routes/Router";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "../context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "./theme";
import { Box } from "@mui/material";
import { SettingsProvider } from "../context/SettingsContext";
import { SnackbarProvider } from "notistack";

function App() {
  if (Config.App.mode === "development") {
    logger.setLevel("debug");
  }
  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <SettingsProvider
            defaultSettings={Config.Module.Settings.defaultSettings}
          >
            <AuthProvider>
              <ThemeProvider>
                <SnackbarProvider>
                  <Box height="100vh" width="100vw">
                    <Router />
                  </Box>
                </SnackbarProvider>
              </ThemeProvider>
            </AuthProvider>
          </SettingsProvider>
        </BrowserRouter>
      </ReduxProvider>
    </HelmetProvider>
  );
}

export default App;
