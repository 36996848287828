import { dispatch, type RootState, useSelector } from "../redux/store";
import React, { createContext, useEffect } from "react";
import Root from "../routes/paths";
import {
  handleRedirect,
  initialize,
  initialState,
  login,
  logout,
} from "../redux/slices/auth";

// ----------------------------------------------------------------------

const AuthContext = createContext({
  ...initialState,
  login: async () => {
    await Promise.resolve();
  },
  logout: async () => {
    await Promise.resolve();
  },
});

// ----------------------------------------------------------------------

interface AuthProviderProps {
  children: React.ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const state = useSelector((state: RootState) => state.auth);
  const isRedirecting = window.location.pathname.includes(
    Root.Auth.Redirect.pathname
  );
  let mustInitialize = !isRedirecting; // used to make sure to initialize only once

  useEffect(() => {
    if (mustInitialize) {
      void dispatch(initialize());
      mustInitialize = false;
    }
  }, []);

  useEffect(() => {
    if (isRedirecting) {
      void dispatch(handleRedirect());
    }
  }, [window.location.pathname]);

  const methods = {
    login: async () => {
      await dispatch(login());
    },
    logout: async () => {
      await dispatch(logout());
    },
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "auth0",
        user: {
          id: state?.user?.sub,
          photoURL: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.name,
          role: "manager",
        },
        ...methods,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
