import Root from "../../../base/routes/paths";
import Loadable from "../../../base/routes/Loadable";
import AuthGuard from "../../../base/gaurds/AuthGuard";
import ClientLayout from "../layouts/ClientLayout";
import { Navigate } from "react-router-dom";
import { lazy } from "react";
import { WorkspaceProvider } from "../../../base/context/WorkspaceContext";
import { ScenarioProvider } from "../../../base/context/ScenarioContext";

const CallAnalytics = Loadable(
  lazy(async () => await import("../@/analytics/CallAnalytics"))
);
const ExplorationPlayground = Loadable(
  lazy(async () => await import("../@/explore/ExplorationPlayground"))
);
const CallAutomations = Loadable(
  lazy(async () => await import("../@/automations/CallAutomations"))
);
const CallBrowser = Loadable(
  lazy(async () => await import("../@/browse/CallBrowser"))
);
const CallIntegrations = Loadable(
  lazy(async () => await import("../@/integrations/CallIntegrations"))
);

const ROUTES = [
  {
    path: Root.Studio.Call.Get(":workspaceId").pathname,
    element: (
      <AuthGuard>
        <WorkspaceProvider>
          <ScenarioProvider>
            <ClientLayout />
          </ScenarioProvider>
        </WorkspaceProvider>
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <Navigate
            to={Root.Studio.Call.Get(":workspaceId").Browser.pathname}
            replace
          />
        ),
        index: true,
      },
      {
        element: <CallAnalytics />,
        path: Root.Studio.Call.Get(":workspaceId").Analytics.pathname,
      },
      {
        element: <ExplorationPlayground />,
        path: Root.Studio.Call.Get(":workspaceId").Analytics.Explore.pathname,
      },
      {
        element: <CallBrowser />,
        path: Root.Studio.Call.Get(":workspaceId").Browser.pathname,
      },
      {
        element: <CallIntegrations />,
        path: Root.Studio.Call.Get(":workspaceId").Integrations.pathname,
      },
      {
        element: <CallAutomations />,
        path: Root.Studio.Call.Get(":workspaceId").Automations.pathname,
      },
    ],
  },
];

export default ROUTES;
