import React, { createContext, useEffect } from "react";
import { dispatch, type RootState, useSelector } from "../redux/store";
import {
  initialState,
  listScenarios,
  type ScenarioState,
  setListOptions,
} from "../redux/slices/scenario";
import useWorkspace from "../hooks/useWorkspace";

// ----------------------------------------------------------------------

const ScenarioContext = createContext(initialState);

// ----------------------------------------------------------------------

interface ScenarioProviderProps {
  children: React.ReactNode;
}

function ScenarioProvider({ children }: ScenarioProviderProps) {
  const state: ScenarioState = useSelector(
    (state: RootState) => state.scenario
  );
  const project = useWorkspace();

  useEffect(() => {
    if (state.listOptions != null) {
      void dispatch(listScenarios(state.listOptions));
    }
  }, [state.listOptions]);

  useEffect(() => {
    if (project.selected?.id !== undefined) {
      dispatch(
        setListOptions({
          ...state.listOptions,
          environmentId: project.selected?.id,
        })
      );
    }
  }, [project.selected?.id]);

  return (
    <ScenarioContext.Provider value={state}>
      {children}
    </ScenarioContext.Provider>
  );
}

export { ScenarioContext, ScenarioProvider };
