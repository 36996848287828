import React, { useState } from "react";
import { Drawer, Box, Button, Chip, Divider, useTheme } from "@mui/material";
import ViewTickets from "./ViewTickets";
import CreateTicket from "./CreateTicket";

interface HelpDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpDrawer: React.FC<HelpDrawerProps> = ({ isOpen, onClose }) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(0);

  const chipStyle = (isActive: boolean) => ({
    borderRadius: theme.spacing(5),
    height: "30px",
    margin: "5px",
    padding: "5px 15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: isActive ?? theme.palette.primary.main,
    },
  });

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      sx={{
        width: "25vw",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: { xs: "100vw", sm: "50vw", md: "25vw" },
          maxWidth: "25vw",
          minWidth: "320px",
          boxSizing: "border-box",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <Box sx={{ marginBottom: "20px" }}>
        <Chip
          color={activeTab === 0 ? "primary" : "default"}
          label="View Tickets"
          onClick={() => {
            setActiveTab(0);
          }}
          sx={chipStyle(activeTab === 0)}
        />
        <Chip
          label="Create Tickets"
          color={activeTab === 1 ? "primary" : "default"}
          onClick={() => {
            setActiveTab(1);
          }}
          sx={chipStyle(activeTab === 1)}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Divider />
      </Box>
      <Box sx={{ width: "100%" }}>
        {activeTab === 0 && <ViewTickets />}
        {activeTab === 1 && <CreateTicket />}
      </Box>
      <Button onClick={onClose} sx={{ marginTop: "auto" }}>
        Close Drawer
      </Button>
    </Drawer>
  );
};

export default HelpDrawer;
