//
//
import { Background } from "reactflow";
import { InputSelectIcon } from "./CustomIcons";

// ----------------------------------------------------------------------

export default function Select(theme) {
  return {
    MuiSelect: {
      sx: {
        backgroundColor: theme.palette.background.paper,
      },
      defaultProps: {
        IconComponent: InputSelectIcon,

        MenuProps: {
          PaperProps: {
            sx: {
              maxHeight: 300,
              backgroundColor: theme.palette.background.paper, // Set the background color here
            },
          },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
        },

        Input: {
          backgroundColor: theme.palette.background.paper, // Set the background color for the outlined input
        },
      },
    },
  };
}
