import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CSTPlatform from "../../services/cst-platform";
import logging from "../../utils/logging";
import { type ListScenariosRequest } from "../../services/cst-platform/api/Organization/Upload/upload.dao";
import { type Scenario } from "../../services/cst-platform/api/Organization/Upload/upload.types";

export interface ScenarioState {
  status: "loading" | "success" | "error";
  uploader: {
    status: "idle" | "uploading";
    error?: string;
    jobQueue: File[];
    errorQueue: File[];
    successQueue: File[];
    progress?: number;
  };
  data: Scenario[];
  pagination?: {
    size: number;
    page: number;
    total: number;
  };
  listOptions?: ListScenariosRequest;
}

export const initialState: ScenarioState = {
  status: "loading",
  uploader: {
    status: "idle",
    jobQueue: [],
    errorQueue: [],
    successQueue: [],
  },
  data: [],
};

// Async thunk for uploading files
export const uploadScenariosFromFiles = createAsyncThunk(
  "scenario/uploadFiles",
  async (
    { files, environmentId }: { files: File[]; environmentId: string },
    { dispatch }
  ) => {
    dispatch(resetUploader());
    dispatch(beginUploading(files));
    for (const file of files) {
      try {
        await uploadSingleFile(file, environmentId);
        dispatch(uploadSuccess(file));
      } catch (error) {
        logging.debug(error);
        dispatch(uploadFailure(file));
      }
    }
    dispatch(finishUpload());
  }
);

export const listScenarios = createAsyncThunk(
  "scenario/list",
  async (request: ListScenariosRequest, { dispatch }) => {
    dispatch(startLoading());
    try {
      const { payload } = await CSTPlatform.Organization.Upload.list(request);
      dispatch(
        listScenariosSuccess({
          data: payload.scenarios,
          pagination: payload.pagination,
        })
      );
    } catch (error) {
      logging.debug(error);
      dispatch(enterError(error));
    }
  }
);

const slice = createSlice({
  name: "scenario",
  initialState,
  reducers: {
    startLoading(state) {
      state.status = "loading";
    },
    enterError(state, action) {
      state.status = "error";
      state.uploader.error = action.payload;
    },
    listScenariosSuccess(state, action) {
      state.status = "success";
      const { data, pagination } = action.payload;
      state.data = data;
      state.pagination = pagination;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
    resetUploader(state) {
      state.uploader = initialState.uploader;
    },
    beginUploading(state, action) {
      const files = action.payload;
      // this is disabled as the data type for action.payload is any and the TS makes and error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      state.uploader.jobQueue.push(...files);
      state.uploader.status = "uploading";
    },
    uploadSuccess(state, action) {
      const file = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      state.uploader.successQueue.push(file);
      state.uploader.jobQueue = state.uploader.jobQueue.filter(
        f => f.name !== file.name
      );
      state.uploader.progress =
        ((state.uploader.successQueue.length +
          state.uploader.errorQueue.length) /
          (state.uploader.successQueue.length +
            state.uploader.errorQueue.length +
            state.uploader.jobQueue.length)) *
        100;
    },
    uploadFailure(state, action) {
      const file = action.payload;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      state.uploader.errorQueue.push(file);
      state.uploader.jobQueue = state.uploader.jobQueue.filter(
        f => f.name !== file.name
      );
    },
    finishUpload(state) {
      state.uploader.status = "idle";
    },
    setListOptions(state, action) {
      state.listOptions = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(uploadScenariosFromFiles.fulfilled, state => {
      state.uploader.status = "idle";
    });
  },
});

// Reducer
export default slice.reducer;

export const {
  startLoading,
  setListOptions,
  uploadSuccess,
  uploadFailure,
  finishUpload,
  beginUploading,
  enterError,
  listScenariosSuccess,
  resetUploader,
} = slice.actions;

// ===============================================

async function uploadSingleFile(file: File, environmentId: string) {
  const { payload } = await CSTPlatform.Organization.Upload.create({
    environmentId,
    name: file.name,
    attributes: {
      type: file.type,
      lastModified: file.lastModified,
      size: file.size,
    },
  });
  const { uploadUrl, id } = payload;
  const response = await fetch(uploadUrl, {
    method: "PUT",
    body: file,
  });
  if (!response.ok) {
    await CSTPlatform.Organization.Upload.delete(id);
    logging.debug(`Failed to upload file ${file.name}, response: `, response);
    throw new Error("Failed to upload file");
  }
  await CSTPlatform.Organization.Upload.publish(id);
}

// ===============================================
