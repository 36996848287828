import {
  createSearchParams,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import LoadingScreen from "../components/LoadingScreen";
import Root from "../routes/paths";

// ----------------------------------------------------------------------

interface AuthGuardProps {
  children: React.ReactNode;
}

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, ...auth } = useAuth();

  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  if (!isInitialized) {
    return <LoadingScreen isActive={true} />;
  }

  if (!isAuthenticated) {
    const search = createSearchParams({
      requested_page: pathname + "?" + searchParams.toString(),
    }).toString();
    return <Navigate to={`${Root.Auth.Login.pathname}?${search}`} />;
  }

  return <>{children}</>;
}
