import * as Yup from "yup";

const Config = {
  clientId: process.env.REACT_APP_PROVIDER_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_PROVIDER_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_PROVIDER_AUTH0_AUDIENCE,
};

export const Auth0Schema = Yup.object().shape({
  clientId: Yup.string().required(),
  domain: Yup.string().required(),
  audience: Yup.string().required(),
});

export type Auth0Config = Yup.InferType<typeof Auth0Schema>;

export default Config as Auth0Config;
