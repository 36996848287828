import CSTPlatform from "../../../CSTPlatform";
import { type ApiResponse } from "../../../types";
import {
  type ArchiveResponse,
  type ArchiveRequest,
  type GetAudioWavUrlRequest,
  type GetAudioWavUrlResponse,
  type RetrieveLabelsResponseDto,
  type RetrieveCallsRequest,
  type RetrieveCallsResponse,
  type RetrieveTopicsResponse,
  type LabelRequestDto,
} from "./call.dto";

const CallApi = {
  async retrieve(
    workspaceId: string,
    request: RetrieveCallsRequest
  ): Promise<ApiResponse<RetrieveCallsResponse>> {
    const response = await CSTPlatform.getInstance().post(
      `/workflows/${workspaceId}/calls/retrieve`,
      request
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },

  async getTopics(
    workspaceId: string
  ): Promise<ApiResponse<RetrieveTopicsResponse>> {
    const response = await CSTPlatform.getInstance().get(
      `/workflows/${workspaceId}/calls/topics`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },

  async getAudioWavUrl(
    workspaceId: string,
    request: GetAudioWavUrlRequest
  ): Promise<ApiResponse<GetAudioWavUrlResponse>> {
    const response = await CSTPlatform.getInstance().get(
      `/workflows/${workspaceId}/calls/${request.callId}/audio/wav`
    );
    return {
      payload: {
        url: response.data,
      },
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async archiveCall(
    workspaceId: string,
    request: ArchiveRequest
  ): Promise<ApiResponse<ArchiveResponse>> {
    const response = await CSTPlatform.getInstance().delete(
      `/workflows/${workspaceId}/calls/${request.callId}`
    );
    return {
      payload: {
        archiveData: response.data,
      },
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async listLabels(
    workspaceId: string
  ): Promise<ApiResponse<RetrieveLabelsResponseDto>> {
    const response = await CSTPlatform.getInstance().get(
      `/workflows/${workspaceId}/calls/labels`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async deleteLabel(
    workspaceId: string,
    label: string
  ): Promise<ApiResponse<void>> {
    const response = await CSTPlatform.getInstance().delete(
      `/workflows/${workspaceId}/calls/labels/${label}`
    );
    return {
      payload: undefined,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async listCallLabels(
    workspaceId: string,
    callId: string
  ): Promise<ApiResponse<string[]>> {
    const response = await CSTPlatform.getInstance().get(
      `/workflows/${workspaceId}/calls/${callId}/labels`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async addLabelsToCall(
    workspaceId: string,
    callId: string,
    labels: LabelRequestDto
  ): Promise<ApiResponse<void>> {
    const response = await CSTPlatform.getInstance().post(
      `/workflows/${workspaceId}/calls/${callId}/labels`,
      labels
    );
    return {
      payload: undefined,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async removeLabelFromCall(
    workspaceId: string,
    callId: string,
    label: string
  ): Promise<ApiResponse<void>> {
    const response = await CSTPlatform.getInstance().delete(
      `/workflows/${workspaceId}/calls/${callId}/labels/${label}`
    );
    return {
      payload: undefined,
      statusCode: response.status,
      message: response.statusText,
    };
  },
};

export default CallApi;
