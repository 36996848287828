import * as Yup from "yup";

// Correcting the type for themeStretch in cookiesKey to boolean.
const Config = {
  cookiesExpires: 3,
  cookiesKey: {
    themeMode: "themeMode",
    themeDirection: "themeDirection",
    themeColorPresets: "themeColorPresets",
    themeLayout: "themeLayout",
    themeStretch: false, // This should be a boolean value.
  },
  defaultSettings: {
    themeMode: "light",
    themeDirection: "ltr",
    themeColorPresets: "default",
    themeLayout: "horizontal",
    themeStretch: false,
  },
};

export const SettingsSchema = Yup.object().shape({
  cookiesExpires: Yup.number().required(),
  cookiesKey: Yup.object().shape({
    themeMode: Yup.string().required(),
    themeDirection: Yup.string().required(),
    themeColorPresets: Yup.string().required(),
    themeLayout: Yup.string().required(),
    themeStretch: Yup.boolean().required(), // Ensure this matches the expected type.
  }),
  defaultSettings: Yup.object().shape({
    themeMode: Yup.string().required(),
    themeDirection: Yup.string().required(),
    themeColorPresets: Yup.string().required(),
    themeLayout: Yup.string().required(),
    themeStretch: Yup.boolean().required(),
  }),
});

export type SettingsConfig = Yup.InferType<typeof SettingsSchema>;

// This type assertion should now be valid.
export default Config as SettingsConfig;
