import { useLocation } from "react-router-dom";
import React, { Suspense } from "react";
import LoadingScreen from "../components/LoadingScreen";

const Loadable = (Component: React.ComponentType<any>) => {
  const WrappedComponent = (props: any) => {
    const { pathname } = useLocation();
    return (
      <Suspense
        fallback={<LoadingScreen isActive={pathname.includes("dashboard")} />}
      >
        <Component {...props} />
      </Suspense>
    );
  };

  // Add display name to the wrapped component
  WrappedComponent.displayName = `Loadable(${Component.displayName ?? Component.name ?? "Component"})`;

  return WrappedComponent;
};

export default Loadable;
