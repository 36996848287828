import * as Yup from "yup";

const Config = {
  publicKey: process.env.REACT_APP_PROVIDER_STRIPE_PUBLIC_KEY,
};

export const StripeSchema = Yup.object().shape({
  publicKey: Yup.string().required(),
});

export type StripeConfig = Yup.InferType<typeof StripeSchema>;

export default Config as StripeConfig;
