import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as GiIcons from "react-icons/gi";
import * as MdIcons from "react-icons/md";
import * as RiIcons from "react-icons/ri";
import * as SiIcons from "react-icons/si";
import * as TiIcons from "react-icons/ti";
import * as VscIcons from "react-icons/vsc";
import * as WiIcons from "react-icons/wi";
import * as BiIcons from "react-icons/bi";
import * as CgIcons from "react-icons/cg";
import * as DiIcons from "react-icons/di";
import * as FcIcons from "react-icons/fc";
import * as GrIcons from "react-icons/gr";
import * as HiIcons from "react-icons/hi";
import * as ImIcons from "react-icons/im";
import * as Io5Icons from "react-icons/io5";
import * as TbIcons from "react-icons/tb";
import * as PiIcons from "react-icons/pi";
import * as FiIcons from "react-icons/fi";
import { Box, useTheme } from "@mui/material";
import { alpha } from "@mui/material/styles";

const ICONS = {
  ...FiIcons,
  ...FaIcons,
  ...PiIcons,
  ...AiIcons,
  ...IoIcons,
  ...GiIcons,
  ...MdIcons,
  ...RiIcons,
  ...SiIcons,
  ...TiIcons,
  ...VscIcons,
  ...WiIcons,
  ...BiIcons,
  ...CgIcons,
  ...DiIcons,
  ...FcIcons,
  ...GrIcons,
  ...HiIcons,
  ...ImIcons,
  ...Io5Icons,
  ...TbIcons,
} as any;

interface ReactIconProps {
  name: string;
  size?: number;
  color?: string;
  sx?: any;
  style?: any;
  variant?: "filled" | "contained";
}

const defaultProps: Partial<ReactIconProps> = {
  variant: "filled",
  size: 24,
  color: "inherit",
  sx: {},
  style: {},
};

export default function ReactIcon(props: ReactIconProps) {
  const theme = useTheme();
  const { name, size, color, sx, variant, ...rest } = {
    ...defaultProps,
    ...props,
  };
  // implement lazy loading
  const Icon = ICONS[name];
  if (variant === "filled") {
    return <Icon {...rest} />;
  } else {
    return (
      color && (
        <Box
          sx={{
            backgroundColor: alpha(color, 0.1),
            borderRadius: "50%",
            padding: theme.spacing(0.5),
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              backgroundColor: alpha(color, 0.2),
              borderRadius: "50%",
              padding: theme.spacing(0.5),
            }}
          >
            <Box
              m={theme.spacing(0.5)}
              width={`${size}px`}
              height={`${size}px`}
              position="relative"
            >
              <Icon
                style={{
                  color,
                  height: "inherit",
                  width: "inherit",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                size={"100%"}
              />
            </Box>
          </Box>
        </Box>
      )
    );
  }
}
