import Root from "../paths";
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import AuthGuard from "../../gaurds/AuthGuard";
import { Navigate } from "react-router-dom";
import Loadable from "../Loadable";
import { lazy } from "react";

const TeamBrowser = Loadable(
  lazy(async () => await import("../../pages/dashboard/WorkspaceBrowser"))
);
const Settings = Loadable(
  lazy(
    async () =>
      await import("../../pages/dashboard/Settings").then(({ Settings }) => ({
        default: Settings,
      }))
  )
);

const DASHBOARD_ROUTES = [
  {
    path: Root.Dashboard.name,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        element: (
          <Navigate to={Root.Dashboard.Workspace.List.pathname} replace />
        ),
        index: true,
      },
      {
        path: Root.Dashboard.Settings.name,
        element: <Settings />,
      },
      {
        path: Root.Dashboard.Workspace.name,
        children: [
          {
            path: Root.Dashboard.Workspace.List.name,
            element: <TeamBrowser />,
          },
        ],
      },
    ],
  },
];

export default DASHBOARD_ROUTES;
