import CSTPlatform from "../../CSTPlatform";
import { type ApiResponse } from "../../types";
import { type GenerateCubeTokenResponse } from "./cube.dto";

const CubeApi = {
  async generateTempToken(
    workflowId: string[]
  ): Promise<ApiResponse<GenerateCubeTokenResponse>> {
    const response = await CSTPlatform.getInstance().post(
      "/cube/temporary-tokens",
      {
        workflowId,
      }
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
};

export default CubeApi;
