import { type ApiResponse } from "../../../types";
import CSTPlatform from "../../../CSTPlatform";
import {
  type CreateScenarioDraftRequest,
  type CreateScenarioDraftResponse,
  type DeleteScenariosRequest,
  type ListScenariosRequest,
  type ListScenariosResponse,
  type UpdateScenarioRequest,
} from "./upload.dao";
import { type Scenario } from "./upload.types";

const UploadApi = {
  async create(
    request: CreateScenarioDraftRequest
  ): Promise<ApiResponse<CreateScenarioDraftResponse>> {
    const response = await CSTPlatform.getInstance().post(
      "/organizations/me/scenarios/drafts",
      request
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async publish(
    scenarioId: string
  ): Promise<ApiResponse<CreateScenarioDraftResponse>> {
    const response = await CSTPlatform.getInstance().post(
      `/organizations/me/scenarios/${scenarioId}/publish`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async list(
    request?: ListScenariosRequest
  ): Promise<ApiResponse<ListScenariosResponse>> {
    const response = await CSTPlatform.getInstance().get(
      `/organizations/me/scenarios/list`,
      {
        params: request,
      }
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async get(scenarioId: string): Promise<ApiResponse<Scenario>> {
    const response = await CSTPlatform.getInstance().get(
      `/organizations/me/scenarios/${scenarioId}`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async delete(scenarioId: string): Promise<ApiResponse<null>> {
    const response = await CSTPlatform.getInstance().delete(
      `/organizations/me/scenarios/${scenarioId}`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async deleteMany(request: DeleteScenariosRequest) {
    const response = await CSTPlatform.getInstance().delete(
      `/organizations/me/scenarios/list`,
      {
        params: {
          scenarioIds: request.ids,
        },
      }
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async update(
    scenarioId: string,
    request: UpdateScenarioRequest
  ): Promise<ApiResponse<Scenario>> {
    const response = await CSTPlatform.getInstance().patch(
      `/organizations/me/scenarios/${scenarioId}`,
      request
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
};

export default UploadApi;
