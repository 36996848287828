import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import GuestGuard from "../../gaurds/GuestGuard";
import Page404 from "../../pages/Page404";
import Loadable from "../Loadable";
import Root from "../paths";
import DASHBOARD_ROUTES from "./dashboard";
import WORKSPACE_ROUTES from "./workspace";
import EXTENSION_ROUTES from "./extensions";

// Authentication
const Login = Loadable(
  lazy(async () => await import("../../pages/auth/Login"))
);
const Redirect = Loadable(
  lazy(async () => await import("../../pages/auth/Redirect"))
);

const ALL_ROUTES = [
  // Root Routes
  {
    path: "/",
    element: <Navigate to={Root.Dashboard.pathname} replace />,
  },
  ...DASHBOARD_ROUTES,
  ...WORKSPACE_ROUTES,
  ...EXTENSION_ROUTES,
  // Auth Routes
  {
    path: Root.Auth.name,
    children: [
      {
        element: <Navigate to={Root.Auth.Login.pathname} replace />,
        index: true,
      },
      {
        path: Root.Auth.Login.name,
        // TODO add gaurds
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: Root.Auth.Logout.name,
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: Root.Auth.Redirect.name,
        element: (
          <GuestGuard>
            <Redirect />
          </GuestGuard>
        ),
      },
    ],
  },
  // Index Routes
  {
    path: "*",
    element: <Page404 />,
  },
];

export default ALL_ROUTES;
