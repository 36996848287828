// @mui
import { useTheme } from "@mui/material/styles";
// hooks
import useResponsive from "../hooks/useResponsive";
import { type Breakpoint } from "@mui/material";

// ----------------------------------------------------------------------

export default function GetFontValue(variant: string) {
  const theme = useTheme();
  const breakpoints = useWidth() as Breakpoint | number;

  const key = theme.breakpoints.up(breakpoints === "xl" ? "lg" : breakpoints);

  const hasResponsive =
    variant === "h1" ||
    variant === "h2" ||
    variant === "h3" ||
    variant === "h4" ||
    variant === "h5" ||
    variant === "h6";

  // this is a temporary solution for the  : theme.typography['h6'];  previous code was 'theme.typography[variant] '
  const getFont: any =
    hasResponsive && theme.typography[variant][key]
      ? theme.typography[variant][key]
      : theme.typography.h6;

  const fontSize = remToPx(getFont.fontSize as string);
  // @ts-expect-error TODO: fix this
  const lineHeight = Number(theme.typography[variant].lineHeight) * fontSize;
  // @ts-expect-error TODO: fix this
  const { fontWeight } = theme.typography[variant];
  // @ts-expect-error TODO: fix this
  const { letterSpacing } = theme.typography[variant];

  return { fontSize, lineHeight, fontWeight, letterSpacing };
}

// ----------------------------------------------------------------------

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

interface ResponsiveFontSizes {
  sm: number;
  md: number;
  lg: number;
}

export function responsiveFontSizes({ sm, md, lg }: ResponsiveFontSizes) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: any, key) => {
      const matches = useResponsive("up", key);
      return !output && matches ? key : output;
    }, null) || "xs"
  );
}
