import { useContext } from "react";
import { WorkspaceContext } from "../context/WorkspaceContext";

// ----------------------------------------------------------------------

const useWorkspace = () => {
  const context = useContext(WorkspaceContext);
  if (!context)
    throw new Error("Workspace contexts must be use inside WorkspaceProvider");
  return context;
};

export default useWorkspace;
