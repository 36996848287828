import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  type SelectChangeEvent,
  Typography,
  FormHelperText,
} from "@mui/material";
import CSTPlatform from "../../../../base/services/cst-platform/CSTPlatform";
import { type CreateItemRequest } from "../../../../base/services/cst-platform/api/Organization/Item/item.dto";
import logger from "../../../../base/utils/logging";
import useWorkspace from "../../../../base/hooks/useWorkspace";

interface TicketData {
  name: string;
  description: string;
  type: string;
  severityLevel: keyof typeof severityLevels | "";
  state: string;
}

interface TicketErrors {
  name: string;
  description: string;
  type: string;
  severityLevel: string;
}

const initialTicketData: TicketData = {
  name: "",
  description: "",
  type: "",
  severityLevel: "",
  state: "open",
};

const severityLevels = {
  "SEV 1":
    "A critical incident that affects a large number of users in production.",
  "SEV 2":
    "A significant problem affecting a limited number of users in production.",
  "SEV 3":
    "An incident that causes errors, minor problems for users, or a heavy system load.",
  "SEV 4":
    "A minor problem that affects the service but doesn't have a serious impact on users.",
  "SEV 5": "A low-level deficiency that causes minor problems.",
};

const CreateTicket: React.FC = () => {
  const [ticketData, setTicketData] = useState<TicketData>(initialTicketData);
  const [errors, setErrors] = useState<TicketErrors>({
    name: "",
    description: "",
    type: "",
    severityLevel: "",
  });

  const workspace = useWorkspace();

  const validate = () => {
    const temp: TicketErrors = {
      name: "",
      description: "",
      type: "",
      severityLevel: "",
    };
    temp.name = ticketData.name ? "" : "This field is required";
    temp.description = ticketData.description ? "" : "This field is required";
    temp.type = ticketData.type ? "" : "This field is required";
    temp.severityLevel = ticketData.severityLevel
      ? ""
      : "This field is required";
    setErrors(temp);
    return Object.values(temp).every(x => x === "");
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTicketData({ ...ticketData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setTicketData({
      ...ticketData,
      [event.target.name as keyof TicketData]: event.target.value,
    });
  };

  const handleCreateItem = async (ticketDataPayload: TicketData) => {
    try {
      const request: CreateItemRequest = {
        associationId: workspace.selected?.id,
        attributes: ticketDataPayload,
      };
      const response = await CSTPlatform.Organization.Item.create(
        "support-tickets",
        request
      );
    } catch (e) {
      logger.debug(e);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      setTicketData(initialTicketData);
      console.log(ticketData);
      void handleCreateItem(ticketData);
      setErrors({ ...initialTicketData });
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5">Raise a ticket</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={ticketData.name}
          onChange={handleInputChange}
          margin="normal"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          multiline
          rows={4}
          value={ticketData.description}
          onChange={handleInputChange}
          margin="normal"
          error={!!errors.description}
          helperText={errors.description}
        />
        <FormControl fullWidth margin="normal" error={!!errors.type}>
          <InputLabel>Type</InputLabel>
          <Select
            name="type"
            value={ticketData.type}
            onChange={handleSelectChange}
            label="Type"
          >
            <MenuItem value="incident">Incident</MenuItem>
            <MenuItem value="improvement">Improvement</MenuItem>
            <MenuItem value="suggestion">Suggestion</MenuItem>
          </Select>
          {!!errors.type && <FormHelperText>{errors.type}</FormHelperText>}
        </FormControl>
        <FormControl fullWidth margin="normal" error={!!errors.severityLevel}>
          <InputLabel>Severity Level</InputLabel>
          <Select
            name="severityLevel"
            value={ticketData.severityLevel}
            onChange={handleSelectChange}
            label="Severity Level"
          >
            {Object.keys(severityLevels).map(level => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </Select>
          {!!ticketData.severityLevel && (
            <Typography variant="caption" sx={{ mt: 1 }}>
              {severityLevels[ticketData.severityLevel]}
            </Typography>
          )}
          {!!errors.severityLevel && (
            <FormHelperText>{errors.severityLevel}</FormHelperText>
          )}
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
        >
          Create Ticket
        </Button>
      </form>
    </Box>
  );
};

export default CreateTicket;
