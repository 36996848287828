import { Fab, useTheme } from "@mui/material";
import { useState } from "react";
import HelpDrawer from "./HelpDrawer";
import ReactIcon from "../../../../base/components/ReactIcon";

const HelpDialogButton: React.FC = () => {
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleHelpClick = () => {
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Fab
        color="primary"
        aria-label="help"
        sx={{
          position: "fixed",
          bottom: theme.spacing(4),
          right: theme.spacing(4),
          padding: "10px 20px",
          borderRadius: "20px",
          textTransform: "none",
          justifyContent: "center",
          alignItems: "center",
          width: "auto",
          height: "auto",
          "& p": {
            margin: 0,
          },
        }}
        onClick={handleHelpClick}
      >
        <p>HELP</p>
        <ReactIcon
          name="AiFillWechat"
          style={{ width: "20px", height: "20px", marginLeft: "5px" }}
        />
      </Fab>
      <HelpDrawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      />
    </>
  );
};

export default HelpDialogButton;
