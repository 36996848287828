import * as Yup from "yup";

const Config = {
  apiUrl: process.env.REACT_APP_PROVIDER_CUBE_API_URL,
};

export const CubeSchema = Yup.object().shape({
  apiUrl: Yup.string().required(),
});

export type CubeConfig = Yup.InferType<typeof CubeSchema>;

export default Config as CubeConfig;
