export function limitText(text: string, limit: number): string {
  if (text.length <= limit) {
    return text;
  }
  return text.substr(0, limit) + "...";
}

export function limitTextWords(text: string, limit: number): string {
  const words = text.split(" ");
  if (words.length <= limit) {
    return text;
  }
  return words.slice(0, limit).join(" ") + "...";
}

export function kebabCaseToTitleCase(text: string): string {
  return text
    .split("-")
    .map(word => word[0].toUpperCase() + word.substr(1))
    .join(" ");
}
