import CST_PATHS from "../../extensions/cst-client/routes/paths";

const Root = {
  path: "/",
  Auth: {
    pathname: "/auth",
    name: "auth",
    Redirect: {
      pathname: "/auth/redirect",
      name: "redirect",
    },
    Login: {
      pathname: "/auth/login",
      name: "login",
    },
    Logout: {
      pathname: "/auth/logout",
      name: "logout",
    },
  },
  Dashboard: {
    pathname: "/dashboard",
    name: "dashboard",
    Settings: {
      pathname: "/dashboard/settings",
      name: "settings",
    },
    Workspace: {
      pathname: "/dashboard/workspaces",
      name: "workspaces",
      List: {
        pathname: "/dashboard/workspaces/list",
        name: "list",
      },
    },
  },
  Workspace: {
    pathname: "/workspaces",
    name: "workspaces",
    Create: {
      pathname: "/workspaces/create",
      name: "create",
      Custom: {
        pathname: "/workspaces/create/custom",
        name: "custom",
      },
    },
    Get: (tokenWorkspace: string) => ({
      pathname: `/workspace/${tokenWorkspace}`,
      name: `${tokenWorkspace}`,
    }),
  },
  Studio: {
    name: "studios",
    pathname: "/studios",
    Call: CST_PATHS,
  },
};

export default Root;
