import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Tooltip,
  useTheme,
  alpha,
} from "@mui/material";
import ReactIcon from "../../../../base/components/ReactIcon";
import { usePromise } from "../../../../base/hooks/usePromise";
import CSTPlatform from "../../../../base/services/cst-platform/CSTPlatform";
import useWorkspace from "../../../../base/hooks/useWorkspace";

interface TicketAttribute {
  id: number;
  name: string;
  description: string;
  type: string;
  state: string;
  severityLevel: string;
}

const ViewTickets: React.FC = () => {
  const [filter, setFilter] = useState("all");
  const workspace = useWorkspace();
  const [response, loading, error] = usePromise(async () => {
    if (workspace.selected?.id) {
      return await CSTPlatform.Organization.Item.list("support-tickets", {
        associationId: workspace.selected.id,
      });
    } else {
      // Handle the case where workspace.selected?.id is null or undefined
      return null; // Or handle it based on your application logic
    }
  });
  const theme = useTheme();

  const [ticketsArray, setTicketsArray] = useState<TicketAttribute[]>([]);

  useEffect(() => {
    const items = response?.payload?.items ?? [];
    setTicketsArray(items.map(item => item.attributes as TicketAttribute));
  }, [response, loading, error]);

  const filteredTickets = ticketsArray?.filter(
    ticket => filter === "all" || ticket?.state === filter
  );
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const severityLevelColor = (level: string) => {
    switch (level) {
      case "SEV 1":
        return alpha(theme.palette.error.main, 0.7);
      case "SEV 2":
        return alpha(theme.palette.warning.main, 0.7);
      case "SEV 3":
        return alpha(theme.palette.info.main, 0.7);
      case "SEV 4":
        return alpha(theme.palette.primary.main, 0.7);
      case "SEV 5":
        return alpha(theme.palette.success.main, 0.7);
      default:
        return theme.palette.grey[500];
    }
  };

  const severityLevelDescription = (level: string) => {
    switch (level) {
      case "SEV 1":
        return "A critical incident that affects a large number of users in production.";
      case "SEV 2":
        return "A significant problem affecting a limited number of users in production.";
      case "SEV 3":
        return "An incident that causes errors, minor problems for users, or a heavy system load.";
      case "SEV 4":
        return "A minor problem that affects the service but doesn't have a serious impact on users.";
      case "SEV 5":
        return "A low-level deficiency that causes minor problems.";
      default:
        return "";
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5">Your Tickets</Typography>
      <FormControl component="fieldset" sx={{ marginBottom: 3 }}>
        <RadioGroup
          row
          aria-label="ticket-state"
          name="row-radio-buttons-group"
          value={filter}
          onChange={handleFilterChange}
        >
          <FormControlLabel value="all" control={<Radio />} label="All" />
          <FormControlLabel value="open" control={<Radio />} label="Open" />
          <FormControlLabel value="closed" control={<Radio />} label="Closed" />
        </RadioGroup>
      </FormControl>
      {filteredTickets.map(ticket => (
        <Accordion key={ticket.name} sx={{ marginBottom: 2, boxShadow: 3 }}>
          <AccordionSummary
            expandIcon={<ReactIcon name="AiFillCaretDown" />}
            aria-controls={`panel${ticket.id}-content`}
            id={`panel${ticket.id}-header`}
          >
            <Typography sx={{ flexShrink: 0, marginRight: 1 }}>
              {ticket.name}
            </Typography>
            <Tooltip
              title={severityLevelDescription(ticket.severityLevel)}
              arrow
            >
              <Chip
                label={ticket.severityLevel}
                size="small"
                sx={{
                  color: "white",
                  marginRight: 1,
                  height: "24px",
                  fontSize: "0.75rem",
                  backgroundColor: severityLevelColor(ticket.severityLevel),
                }}
              />
            </Tooltip>
            <Chip
              label={ticket?.state.toUpperCase()}
              color={ticket?.state === "open" ? "success" : "error"}
              size="small"
              sx={{ color: "white", height: "24px", fontSize: "0.75rem" }}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Type: {ticket.type}</Typography>
            <Typography>Description: {ticket.description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ViewTickets;
