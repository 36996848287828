import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function LoadingScreen({ isActive }: { isActive: boolean }) {
  if (!isActive) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999, // Adjust the zIndex as needed
      }}
    >
      <Box>
        <CircularProgress
          size={40}
          thickness={5}
          style={{
            animation: "rotation 2s linear infinite",
          }}
        />
      </Box>
    </div>
  );
}
