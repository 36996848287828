import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import authReducer from "./slices/auth";
import scenarioReducer from "./slices/scenario";
import workspaceReducer from "./slices/workspace";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

// const examplePersistConfig = {
//     key: 'example',
//     storage,
//     keyPrefix: 'redux-',
//     whitelist: [
//         // 'savedKey1',
//         // 'savedKey2',
//        ...
//     ],
// }
//
// const studioReducer = combineReducers({
//     ehr: ehrStudioReducer,
// });

const rootReducer = combineReducers({
  auth: authReducer,
  // studio: studioReducer,
  scenario: scenarioReducer,
  workspace: workspaceReducer,
});

export default rootReducer;

export { rootPersistConfig, rootReducer };
