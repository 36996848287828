import * as Yup from "yup";
import StripeConfig, { StripeSchema } from "./stripe.config";
import Auth0Config, { Auth0Schema } from "./auth0.config";
import AnalyticsConfig, { AnalyticsSchema } from "./analytics.config";
import SettingsConfig, { SettingsSchema } from "./settings.config";
import logger from "../../utils/logging";
import CubeConfig, { CubeSchema } from "./cube.config";

const Config = {
  App: {
    mode: process.env.NODE_ENV,
  },
  Provider: {
    Base: {
      uri: process.env.REACT_APP_PROVIDER_BASE_URI,
    },
    Stripe: StripeConfig,
    Auth0: Auth0Config,
    Analytics: AnalyticsConfig,
    Cube: CubeConfig,
  },
  Module: {
    Settings: SettingsConfig,
  },
};

const APIProviderSchema = Yup.object().shape({
  uri: Yup.string().required(),
});

const Schema = Yup.object().shape({
  App: Yup.object()
    .shape({
      mode: Yup.string().required(),
    })
    .required(),
  Provider: Yup.object()
    .shape({
      Base: APIProviderSchema.required(),
      Stripe: StripeSchema.required(),
      Auth0: Auth0Schema.required(),
      Analytics: AnalyticsSchema.required(),
      Cube: CubeSchema.required(),
    })
    .required(),
  Module: Yup.object()
    .shape({
      Settings: SettingsSchema.required(),
    })
    .required(),
});

let cachedConfig: AppConfig | null = null;

export function loadConfig(): AppConfig {
  if (cachedConfig !== null) {
    return cachedConfig;
  }
  const valid = Schema.validateSync(Config);
  cachedConfig = valid;
  logger.info("CONFIG :: loaded config");
  return valid;
}

export type AppConfig = Yup.InferType<typeof Schema>;

const RootConfig = loadConfig();

export default RootConfig;
